$tablet-min: 768px;
$mobile-max: 480px;
$tablet-max: 1024px;

@mixin mobile {
    @media screen  and (max-width: #{$mobile-max}) { // mobile
        @content;
    }
}

@mixin tablet-min {
    @media screen  and (max-width: #{$tablet-min}) { // Tablet min
        @content;
    }
}

@mixin tablet-max {
    @media screen  and (max-width: #{$tablet-max}) { // tablet max
        @content;
    }
}

:root {
    --color-primary:#1468B2;
    --color-primaryBlue:#1468B2;
    --color-secondary:#7F7F7F;
    --color-sidebarHover : #f6f6f6;
    --color-liteGrey: #B5B5B5;
    --color-primaryBorder:#C4C5C5;
    --color-customlabel: #43425D;
    // --color-primary:#b24ff7;
    --color-light-primary:#edd3ff;
    --color-white: #ffffff;
    --color-darkBg: #000000;
    --color-liteBorder:#F5F6F7;
    --color-borderLine:#d4dae3;
    --color-red:#F11010;
    --color-body-BG-grey:#F5F6F6;
    --color-success:#10C92D;
    --color-start-color:#ffc107;

    --font-muliFont:"muli";
}
.marginBottomRg{
    margin-bottom: 1em;
    @include mobile{
        margin-bottom: 0;
    }
}
.fontWeight600{
    font-weight: 600;
}
.fontWeight700{
    font-weight: 700;
}
.fontWeight800{
    font-weight: 800;
}
.heading18Bold{
    font-size:18px;
    font-weight:bold;
    @include mobile{
        font-size: 16px;
        padding: 0 9px 0 0;
    }
}
.sub14Bold{
    font-size:14px;
    font-weight:bold;
}
.sub14regular{
    font-size:14px;
    font-weight:normal;
}
.paddingtopbotom{
    padding: 1em;
    @include mobile{
        padding: 1em .2em;
    }
}
.paddingTop10Bottom20{
    padding:10px 0 20px 0;
}
.paddingTop10Bottom10{
    padding:10px 0 10px 0;
}
.borderRadius5rem{
    border-radius: 0.5rem;
}
.ant-form-item{
    margin-bottom: 0 !important;
}

.separator{
    width: 100%;
    opacity: 0.3;
    color:var(--color-darkBg)
}

.margin0{
    margin:0px;
}

.tableTitle{
    display: flex;
    justify-content: left;
    h2{
        font-size: 18px;
        font-weight: 600;
        @include mobile{
            font-size: 16px;
            padding-bottom: 10px;
        }
    }
    @include mobile{
        justify-content: center;
    }
}
.respCommonPadding{
    @include mobile{
        margin: 1em 0;
    }
}
.invoceCount{
    display: flex;
    justify-content: center;
    padding: .2em 0;
}
.invoiceStatus{
    margin: 0;
    padding: 4px;
    font-size: 14px;
    font-weight: 600;
}
.invoiceNumbers{
    border-radius: 50px;
    margin: 0 .5em;
    color: var(--color-white);
    padding: 3px 0;
    width: 28px;
    height: 28px;
    text-align: center;
}
.colorBlue{
    color: var(--color-primary);
}
.bgBlue{
    background-color: var(--color-primary);
}
.bgRed{
    background-color: var(--color-red);
}

.ediHeading{
    text-align: left;
}
.bgWhite{
    background-color: var(--color-white);
}
.paddingRight10{
    padding-right: 10px;
}
.paddingLeft10{
    padding-left: 10px;
}
.paddingLeft20{
    padding-left: 20px;
}
.paddingTop10{
    padding-top: 10px;
}
.paddingTop20{
    padding-top: 20px;
}
.capitalizeAllCaps{
   text-transform: capitalize;
}
.textAlignCenter{
    text-align: center;
}
.popupContent{
    .capitalize{
        text-transform: capitalize !important;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 600;
        width: 185px;
    }
    p{
        margin: 0;
        padding: 0;
        width: 185px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
    }
}
.padding1em{
    padding: 1em;
}
.alignItemsRight{
    float: right;
}
.displayFlex{
    display: flex;
}
.justifyContentCenter{
    justify-content: center;
}
.justifyContentFlexEnd{
    justify-content: flex-end;
}
.alignItemsCenter{
    align-items: center;
}
.editIcon{
    cursor: pointer;
}
.displaySpacebetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.displaySpaceEvenly{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.width150{
    width: 150px;
}
.input-error{
    color: var(--color-red);
}
.width200{
    width: 200px;
}
.width350{
    width: 350px;
}
.clrRed{
    color: var(--color-red);
}
.primaryBlue{
    color: var(--color-primaryBlue);
}
.width300{
    width: 300px;
}
.navTopBottom1em{
    padding: 1em 0;
}
.colorWhite{
    color: var(--color-white);
}
.customeSideLabel{
    color: var(--color-customlabel);
    font-size: 15px;
    margin: 0;
    padding: 0;
    @include tablet-min{
        padding-bottom: 10px;
    }
}
.beforeLabelStar::before{
    content:'*' !important;
    color: var(--color-red);
}

.ant-btn-primary, .ant-btn-primary:hover, .ant-btn-primary:focus{
    color: #000000;
    background: var(--color-liteBorder);
    border-color: var(--color-borderLine);
}
.alignCenter{
    text-align:center;
}


.primary-color-table{
    .ant-table-container table > thead > tr:first-child th:first-child{
        border-top-left-radius: 5px !important;
    }
    .ant-table-container table > thead > tr:last-child th:last-child{
        border-top-right-radius: 5px !important;
    }
    .ant-table-thead tr th.ant-table-cell{
        background:var(--color-primaryBlue);
        color: var(--color-white);
        font-weight: bold;
        font-size: 13px;
    }
    .ant-table-tbody > tr > td{
        font-size: 14px;
        // cursor: pointer;
        font-weight: 400;
        width: 200PX;
    }
}

.loadingSpinnerStylesBlue{
    text-align: center;
    .ant-spin-dot-item{
        background-color:var(--color-primary);
    }
}

.loadingSpinnerStylesWhite{
    text-align: center;
    .ant-spin-dot-item{
        background-color: var(--color-white);
    }
}
.antd-input{
    border: .5px solid var(--color-primaryBorder) !important;
}
.antd-input:focus{
    border: 1px solid var(--color-primary) !important;
}
// .ant-picker:hover,.ant-picker-focused{
//     border-color: var(--color-primary);
//     box-shadow: 0 0 0 0.2px var(--color-primary);
// }

.ant-input:focus, .ant-input-focused,
.ant-input-number:focus, .ant-input-number-focused{
    border-color: var(--color-primary);
    box-shadow: 0 0 0 0.5px var(--color-primary);
}
.datePickerLabel .datePickerStyles.ant-picker .ant-picker-suffix{
    color: var(--color-primaryBorder) !important;
}
.ant-form-item-label > label{
    color: var(--color-primaryBorder);
}

.cursorpointer{
    cursor:pointer;
}
.cursorNoDrop{
    cursor:no-drop;
}
.custom-list-inline{
    padding:0px;
    margin:0px;
    display: inline-flex;
  li{
    display:inline-block;
    vertical-align: middle;
 }
}