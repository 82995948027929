.datePickerLabel{
    display: flex;
    width :100%!important;
    float:left;
    margin: 0 !important;
    color: var(--color-primaryBorder) !important;
.datePickerStyles.ant-picker {
    border:0.5px solid var(--color-primaryBorder) !important;
    border-radius: 5px !important;
    padding: 4px 15px;
 .ant-calendar-picker-icon{
    padding-right: 20px;
}
 .ant-picker-input input{
    padding: 2px 0px;
}
.ant-picker-input > input{
 color: var(--color-primaryBorder);
}
 .ant-picker-suffix{
    color: var(--color-primary);
}
}
.errorDatePickerStyles{
    color: var(--color-red);
    padding-top: 8px;
    margin:2px 0px !important;
}
}
