
$tablet-min: 768px;
$mobile-max: 480px;
$tablet-max: 1024px;

@mixin mobile {
    @media screen  and (max-width: #{$mobile-max}) { // mobile
        @content;
    }
}

@mixin tablet-min {
    @media screen  and (max-width: #{$tablet-min}) { // Tablet min
        @content;
    }
}

@mixin tablet-max {
    @media screen  and (max-width: #{$tablet-max}) { // tablet max
        @content;
    }
}

.layout-main{
    .topHeader{
        background: var(--color-white);
        display: flex;
        @include mobile{
            display: flex !important;
            // position: sticky;
        }
        
        .hamburgerBG{
            left: 0px;
            max-width: 70px !important;
            width: 70px !important;
            border: 2px solid var(--color-primary);
            background: var(--color-primary);
            @include tablet-min{
            background: var(--color-white);
            border: 2px solid var(--color-white);
            }
            .mobileLogo{
                height: 60px;
                color:white;
                text-align:center;
                font-size: 18px;
                display: none;
                @include tablet-min{
                    color: var(--color-primary);
                    font-size: 20px;
                    font-weight: bold;
                    display: block;
                }
                .ant-menu{
                    background-color: var(--color-primary);
                    color: var(--color-white);
                }
            } 
            .DesktopLogo{
                height: 60px;
                color:white;
                text-align:center;
                font-size: 18px;
                @include tablet-min{
                    display: none;
                }
                .ant-menu{
                    background-color: var(--color-primary);
                    color: var(--color-white);
                }
            } 
        }
      
        .verticalCenter{
            width: 100%;
            .nonResp{
                width: 100%;
                object-fit: cover;
                @include mobile {
                    display: none;
                }
            }
            .inResp{
                display: none !important;
                @include mobile {
                    // display: grid !important;
                }
            }
            @include mobile {
                display: flex;
                justify-content: center;
            }
        }
        .paddingRight{
            padding:1em 2em;
        }
        .logoTitle{
            color: var(--color-primary);
            margin: 0;
            padding: 0 0 3px 0;
            span{
                font-weight: bold;
            }
            @include mobile {
                font-size: 16px;
                text-align: center !important;
                justify-content: center;
            }
        }
        .MenuBg-main{
            display: flex;
            justify-content: flex-end;
            padding-right: 3em;
            @include mobile {
                padding-right:15px;
            }
        }
       .MenuBg{
           line-height: 0px;
           .custom-list-inline{
               list-style: none;
               padding: 0;
               margin: 0;
           }
           .companySelectStyles{
               padding:1em;
               @include mobile{
                    padding:1em 0;
                }
           }
           .companySelectStyles:nth-child(2){
            padding:1em 0;
            }
            .companySelectStyles:nth-child(3){
                padding:1em 0;
                }
           .hideInResp{
               @include mobile{
                   display: none;
               }
           }
        .profileStyles{
            -ms-flex-direction: Column;
            flex-direction: Column;
            -ms-flex-pack: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            margin-top: .9em;
            @include mobile{
                margin-top: 15px;
            }
            .user-manual{
                svg{
                    width: 32px;
                    height: 32px;
                    color: var(--color-primary);
                 //    background-color: var(--color-white);
                 }
            }    
        }
        .ProfileBgColor {
            background-color:var(--color-primary) !important;
            color: var(--color-white);
            display: inline-block;
            // margin: 0px 0px 2px 30px;
            text-align: center;
            white-space: nowrap;
            position: relative;
            overflow: hidden;
            width: 32px!important;
            height: 32px!important;
            border-radius: 20px;
            .ant-avatar-string {
                font-size: 25px;
                font-weight: 700;
                position: absolute;
                left: 50%;
                line-height: 30px!important;
                /* transform-origin: 0 center; */
            }
        }
        .profileTitle {
            color:var(--color-darktext);
            line-height: 0;
            font-size: 12px;
            font-weight: 600;
            margin-top: .9em;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .profileTitleStyles{
            color: var(--color-subTitleColor);
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            -webkit-line-clamp: 1;
            line-height: 0.9rem;
            padding-right: 4px;
            }
            .ProfileDropdown{
                font-size: 11px;
                color:#00000040;
            }
         }
       }    
    }
    .topHeader.ant-layout-header{
        padding:0px;
        height: 63px;
    }

    .leftMenu{
        left: 0px;
        flex: 0 0 70px !important;
        max-width: 70px !important;
        min-width: 70px !important;
        width: 70px !important;
        background: var(--color-primary);
        padding-top: 1.9em;
        .ant-menu-vertical .ant-menu-submenu-title{
            padding: 0px;
            text-align: center;
        }
        @include tablet-min {
            display: none;
        }
    }

    .SubHeader{
        display: none;
        @include tablet-min{
            display: grid;
            background-color: var(--color-body-BG-grey);
            height: 50px;
            // position: fixed;
        }
        .respFexCenter{
            @include  tablet-min{
                display: flex;
                justify-content: center;
            }
        }
    }
    .main-body{
        height: '81.5vh';
        background:  var(--color-body-BG-grey);
        .container{
            padding: 2em;
            @include mobile {
                padding: 1em;
            }
        }
    }
    
    .layoutFooter{
        text-align: center;
       background: var(--color-white);
       height: 63px;
       padding:15px 0px;
       border-top:1px solid var(--color-borderLine);
       font-size: 18px;
    }
}

.leftMenu-main{
    .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
        border-right: 0px solid #f0f0f0;
    }
    .bbfitnessLogo{
        height: 63px;
        width: 63px;
        cursor: pointer;
    }
    .ant-menu-submenu{
        width: 63px !important;
        border-right: 1px solid var(--color-borderLine);
    }
    .left-menu-styles{
        margin:0 !important;
        background-color: var(--color-primary);
        color: var(--color-white);
        padding:0 0 2px;
        height: 50px;
       
        // margin-bottom: 1px;
        .svg-icon-active{
            background: var(--color-sidebarHover);
            margin: 0 0 0 5px;
            border-radius: 25px 0 0 25px;
            display: flex;
            padding: .5em;
            height: 100%;
            justify-content: center;
            border-right: 1px solid #f0f0f0;
            .leftMenuIcon{
                color: var(--color-primary);
                position: absolute;
                width: 18px;
                height: 20px;
            }
            p{
                padding: 9px 0 0 0;
                color: var(--color-primary);
                font-size: 8px;
                margin: 0;
                font-weight: bold;
            }
        }
        .svg-icon-normal{
            margin: 0 0 0 5px;
            border-radius: 25px 0 0 25px;
            display: flex;
            padding: .5em;
            height: 100%;
            justify-content: center;
            .leftMenuIcon{
                position: absolute;
                width: 18px;
                height: 20px;
            }
            p{
                padding: 9px 0 0 0;
                font-size: 8px;
                margin: 0;
                font-weight: bold;
                color: #70A9DC;
            }
        }
    }
   .ant-menu-submenu-arrow{
       display: none;
   }
}

.drawerMenuStyles {
    .ant-drawer-content{
       width: 40%;
       .ant-drawer-header{
        background-color: var(--color-primary);
        border-bottom: 0px solid var(--color-white);
        .ant-drawer-close{
            color: var(--color-white);
        }
       }
       .ant-drawer-body{
           padding:0px;
           background-color: var(--color-primary);
           .leftMenu-main{
           padding-top: 1rem;
           }
       }
   }
    .ant-drawer-content-wrapper{
            box-shadow: 0px 0px 0px 0px !important;
            width: 174px !important;
     }
}