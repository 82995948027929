$tablet-min: 768px;
$mobile-max: 480px;
$tablet-max: 1024px;

@mixin mobile {
    @media screen  and (max-width: #{$mobile-max}) { // mobile
        @content;
    }
}

@mixin tablet-min {
    @media screen  and (max-width: #{$tablet-min}) { // Tablet min
        @content;
    }
}

@mixin tablet-max {
    @media screen  and (max-width: #{$tablet-max}) { // tablet max
        @content;
    }
}

.editableTableError{
    .ant-form-item-explain.ant-form-item-explain-error{
         font-size: 8px;
         color:var(--color-darkBg);
   }
}
.editableTableError.ant-form-item-has-error .ant-input-number, .ant-form-item-has-error .ant-picker,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
 .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
  .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
   .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover{
    border-color:var(--color-primary);
}
.productMappingTable {
    .ant-table-tbody > tr > td:nth-child(10),
    .ant-table-tbody > tr > td:nth-child(5){
         font-size: 12px;
         font-weight: 500;
    }
    .productTable_RRL{
        right: 512px;
    }
    .productTable_RRLArticle{
        right: 361px;
    }
    .productTable_RRLArticleDesc{
        right: 78px;
    }
    .productTable_edit{
        right: 2px;
    }
    .productTable_fixedRight{
         position: sticky !important;
          z-index: 2;
         background: var(--color-white);
         @include tablet-min{
            position: relative !important; 
            right: inherit; 
         }
    }
   
    .productTable_RRL::after{
        box-shadow: inset -10px 0 8px -8px var(--color-borderLine);
        position: absolute;
        top: 0;
        bottom: -1px;
        left: 0;
        width: 30px;
        transform: translateX(-100%);
        transition: box-shadow 0.3s;
        content: '';
        pointer-events: none;
        @include tablet-min{
            box-shadow: 0px var(--color-borderLine);
         }
    }
}