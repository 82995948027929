  .regularSize.ant-btn{
      background: var(--color-primary);
      color: var(--color-white);
      width: 125px;
      height: 35px;
      border-radius: 5px;
  }
  .regularSize.ant-btn:hover{
    background: var(--color-primary);
    width: 125px;
    height: 35px;
    color: var(--color-white);
    border-radius: 5px;
  }
  .loginSSO.ant-btn, .loginSSO.ant-btn:hover{
    background: var(--color-primary);
    color: var(--color-white);
    width: 200px;
    height: 48px;
    border-radius: 10px;
    opacity: 1;
    font-weight: bold;
    font-size: 18px;
  }
 
  .createUserBtn.ant-btn, .createUserBtn.ant-btn:hover{
    background: var(--color-primary);
    // width: 116px;
    color: var(--color-white);
    border-radius: 5px;
    opacity: 1;
    font-size: 12px;
  }

  .primaryBtn.ant-btn, .primaryBtn.ant-btn:hover{
    background: var(--color-primary);
    color: var(--color-white);
    border-radius: 5px;
    opacity: 1;
  }

  .secondaryBtn.ant-btn,.secondaryBtn.ant-btn:hover{
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    border-radius: 5px;
    opacity: 1;
  }
  
  .cancelBtn.ant-btn, .cancelBtn.ant-btn:hover{
    background: var(--color-white);
    width: 150px;
    color: var(--color-primary);
    border-radius: 4px;
    height: 40px;
    border: 1px solid var(--color-primary);
    opacity: 1;
    font-size: 12px;
  }
  .submitBtn.ant-btn, .submitBtn.ant-btn:hover{
    background: var(--color-primary);
    width: 150px;
    color: var(--color-white);
    border-radius: 5px;
    height: 40px;
    opacity: 1;
  }
  .PrevNext,.PrevNext:hover,.PrevNext:focus{
    background: var(--color-primary);
    color: var(--color-white);
    width: 30px;
    height: 30px;
    border-radius: 4px;
    opacity: 1;
    padding: 0;
  }
  .loginSSO-pegasus.ant-btn,.loginSSO-pegasus.ant-btn:hover{
  
 
    background: var(--color-primary);
    color: var(--color-white);
    width: 200px;
    height: 48px;
    border-radius: 10px;
    opacity: 1;
    font-weight: bold;
    font-size: 18px;
    // margin-top: 20px;
    
    }
 