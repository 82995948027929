.FilterModal{
    border-radius: 15px;
    .buttonsInline{
        display: flex;
        justify-content: flex-end;
        .clearFilter{
            color: var(--color-primary) !important;
            background-color: var(--color-white) !important;
            width: 100px;
            margin-right: 2em;
            border-radius: 5px;
            border:1px solid var(--color-primary)
          }
          .filterButton{
            color: var(--color-white) !important;
            background-color: var(--color-primary) !important;
            background: var(--color-primary) !important;
            width: 100px;
            border-radius: 5px;
          }
    }
    .ant-modal-content{
        border-radius: 10px;
        .ant-modal-header{
            border-radius: 10px 10px 0 0;
        }
    }
}