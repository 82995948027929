.companies-li{
    .card-li{
        margin: 1em auto !important;
        width: 350px;
        .companyDiv{
            background-color: var(--color-body-BG-grey);
            border: 1px solid var(--color-liteBorder);
            padding: 1em;
            margin-bottom: .5em;
            h2{
                margin: 0;
                padding: 0;
                font-weight: 500;
            }
        }
        .companyDiv:hover{
            background-color: var(--color-white);
            border: 1px solid var(--color-primary);
            padding: 1em;
            margin-bottom: .5em;
            h2:hover{
                margin: 0;
                padding: 0;
                color: var(--color-primary);
                font-weight: 500;
            }
        }
    }
    .ant-card-head-title{
        color: var(--color-primary);
        font-size: 20px;
        font-weight: bold;
    }
}