body {
  margin: 0;
  font-family: muli, sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* @font-face {
  font-family: 'CustomFont1Name';
  src: local('CustomFont1Name'),
       url('./fonts/MultiRegular.woff') format('woff');
} */
/* @font-face {
  font-family: 'CustomFont2Name':
  src: local('CustomFont2Name'),
       url('./fonts/CustomFont2Name.woff') format('woff'),
       url('./fonts/CustomFont2Name.woff2') format('woff2');
} */
