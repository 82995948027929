$tablet: 1024px;
$mobile-max: 480px;

@mixin tablet {
    @media screen  and (max-width: #{$tablet}) { // Tablet 
        @content;
    }
}

@mixin mobile {
    @media screen  and (max-width: #{$mobile-max}) { // Tablet 
        @content;
    }
}
.tcpl-loginpage-main{
    .bannerBlock{
        height: 100vh;
        background: no-repeat;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @include mobile{
            height: 30vh;
        }
    }
    .loginSec{
        .floatRight{
            text-align: right;
            padding: 3em;
            img{
                object-fit: cover;
                height: 100%;
            }
            @include mobile{
                text-align: center;
                padding: 1em;
            }
        }
        .respHide{
            @include mobile{
                display: none !important;
            }
        }
        .textCenter{
            text-align: center;
            margin: 5em 5.5em; 
            h1{
                color: var(--color-primary);
                margin: 0;
                padding: 0;
                font-size: 32px;
                font-weight: bold;
            }        
            img{
                margin: 2em 0;
                width: 100%;
                padding: 0 2.2em;
            }   
            @include mobile{
                margin: 4em 0;
            }
        }
        .paddingSame{
            padding:3em 0;
            @include mobile{
                padding: 1em;
            }
        }
    }
}
.login-div{
    display: flex;
    padding: 10px;
    justify-content: space-around;
    margin-left: -30px;
}