.autoCompleteStyles {
    .ant-select-selector{
    border: 1px solidvar(--color-borderLine) !important;
    border-radius: 5px !important;
    padding:2px 15px !important;
    height: 36px !important;
    }
    .ant-select-arrow{
        padding-right: 15px;
    }
 :hover,:active,.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color:var(--color-primary);
}
}
.autoCompleteStyles.borderNone{
     .ant-select-selector,.ant-select-selector:focus-visible{
        border: 0px;
    }
}
.errorAutoCompleteSection{
    color: var(--color-red);
    padding-top: 8px;
    margin: 0px !important;
}
