$tablet-min: 768px;
$mobile-max: 480px;
$tablet-max: 1024px;

@mixin mobile {
    @media screen  and (max-width: #{$mobile-max}) { // mobile
        @content;
    }
}

@mixin tablet-min {
    @media screen  and (max-width: #{$tablet-min}) { // Tablet min
        @content;
    }
}

@mixin tablet-max {
    @media screen  and (max-width: #{$tablet-max}) { // tablet max
        @content;
    }
}

.masterDataMappingTable {
    .ant-table-tbody > tr > td:nth-child(8){
         font-size: 12px;
         font-weight: 500;
    }
    .masterTable_CustProdID{
        right: 418px;
    }
    .masterTable_CustDC{
        right: 321px;
    }
    .masterTable_CustProdDesc{
        right: 2px;
    }
    .masterTable_FixedRight{
         position: sticky !important;
          z-index: 2;
         background: var(--color-white);
         @include tablet-min{
            position: relative !important; 
            right: inherit; 
         }
    }
   
    .masterTable_CustProdID::after{
        box-shadow: inset -10px 0 8px -8px var(--color-borderLine);
        position: absolute;
        top: 0;
        bottom: -1px;
        left: 0;
        width: 30px;
        transform: translateX(-100%);
        transition: box-shadow 0.3s;
        content: '';
        pointer-events: none;
        @include tablet-min{
            box-shadow: 0px var(--color-borderLine);
         }
    }
}
.cursorTRPointer{
    table{
        tbody{
            tr{
                cursor: pointer;
            }
        }
    }
}