.customUploadBtn{
    .ant-btn:hover, .ant-btn:focus{
       color: var(--color-primary);
       border-color: var(--color-primary);
    }
    .ant-btn{
        border-radius: 5px;
    }
    .file-success-msg{
       color: var(--color-primary);
    }
    .spinStyles{
        .spinSize{
            font-size: 10px;
        }
    }
    .loadingModal {
        width: 270px !important;
        top: 200px !important;
        .ant-modal-close-x {
          display: none !important;
        }
        .ant-modal-content {
          background-color: #d2cbcb00 !important;
          color: var(--color-primary);
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
        .ant-modal-footer {
          display: none !important;
        }
      }
}
