$tablet: 1024px;
$mobile-max: 480px;

@mixin tablet {
    @media screen  and (max-width: #{$tablet}) { // Tablet 
        @content;
    }
}

@mixin mobile {
    @media screen  and (max-width: #{$mobile-max}) { // Tablet 
        @content;
    }
}
.tabsMain{
    background: var(--color-body-BG-grey);
      .ant-tabs-nav .ant-tabs-tab,
       div > .ant-tabs-nav .ant-tabs-tab{
         border-radius: 10px !important;
    }
    .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav{
        margin: 0 !important;
    }
    .ant-tabs-nav{
        margin: 0 !important;
    }
    .ant-tabs-tab{
        padding:0 !important; 
        border: none !important;
        margin-bottom: 0 !important;
    }
    .ant-tabs-tab .ant-tabs-tab-btn{
        // color: var(--color-primary) !important;  
        font-size: 16px; 
        border-radius: 10px 10px 0 0;
        border: .5px solid #80808017;
        width: 220px;
        padding: 1em;
        border-bottom: 2px solid var(--color-body-BG-grey) !important;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0 !important;
        @include mobile {
            width: 150px !important;
        }
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn{
        color: var(--color-primary) !important;  
        font-size: 17px; 
        padding: 0.9em .8em;
        border-radius: 10px 10px 0 0;
        border: .5px solid #80808017;
        width: 220px;
        border-bottom: 2px solid var(--color-primary) !important;
        font-weight: bold;
        text-align: center;
        margin-bottom: 0 !important;
        @include mobile {
            width: 150px !important;
        }
    }
    .datePickers{
        display: flex;
        justify-content: flex-end;
        @include mobile {
            justify-content: space-evenly;
        }
        .datesBlock{
            display: flex;
            justify-content: space-evenly;
            align-items: baseline;
            padding: 0 5px;
            p{
                padding: 0 5px 0 0;
                color: var(--color-primaryBorder);
            }
        }
    }
    .create-user{
        text-align: right;
    }
}