.checkBoxStyles{
    .ant-checkbox-inner{
       width:22px !important; 
       height:22px !important;
     }
     .ant-checkbox-checked{
         .ant-checkbox-inner{
            background-color:var(--color-primary) !important;
            border-color: var(--color-primary) !important;
         }
    }
}