$tablet: 1024px;
$mobile-max: 480px;

@mixin tablet {
  @media screen  and (max-width: #{$tablet}) {
    // Tablet
    @content;
  }
}

@mixin mobile {
  @media screen  and (max-width: #{$mobile-max}) {
    // Tablet
    @content;
  }
}
.viewDetailsPage {
  padding: 1.2em 2rem;
  width: 100%;
  @include mobile {
    padding: 1em 10px !important;
  }
}
.detailsInline {
  display: flex;
  justify-content: space-between;
  p {
    span {
      font-weight: bold;
    }
  }
}

.po-data-innerScreen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .EANRadioBtnStyles .ant-radio-button-wrapper {
    font-size: 12px;
    width: 135px;
  }
  .MRPRadioBtnStyles .ant-radio-button-wrapper {
    font-size: 12px;
    width: 134px;
  }
  .navtoCls {
    padding-left: 20px;
    margin-top: -12px;
    @include mobile {
      padding-left: 0px;
    }
  }
}

.po-data {
  .elements {
    .displayFlex {
      display: flex !important;
      justify-content: start;
      padding-top: 20px;
      cursor: pointer;
      .iconColorStyles svg {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        padding: 10px;
        color: var(--color-white);
        @include mobile {
          width: 30px;
          height: 30px;
          padding: 7px;
        }
        @include tablet {
          width: 32px;
          height: 32px;
          padding: 8px;
        }
      }
      .tickIcon-greenColor-styles svg {
        background-color: var(--color-success);
      }
      .tickIcon-yellowColor-styles svg {
        background-color: #ffbf00;
      }
      .tickIcon-greyColor-styles svg {
        background-color: var(--color-liteGrey);
      }
      .cancelIcon-redColor-styles svg {
        background-color: var(--color-red);
      }
      .labels {
        padding-left: 20px;
        @include tablet {
          padding-left: 15px;
        }
        h3 {
          margin: 0px;
          font-size: 15px;
          font-weight: 700;
          @include mobile {
            font-size: 13px;
          }
          @include tablet {
            font-size: 13px;
          }
        }
        p {
          margin: 0px;
          font-size: 11px;
        }
      }
    }
  }
}

.loadingModal {
  width: 270px !important;
  top: 200px !important;
  .ant-modal-close-x {
    display: none !important;
  }
  .ant-modal-content {
    background-color: #d2cbcb00 !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .ant-modal-footer {
    display: none !important;
  }
  .colorWhite{
    .ant-spin-dot{
    color: var(--color-white);
    }
  }
}
