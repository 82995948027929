.popoverMain{
    border: 20px;
}
    .ant-popover-content{
        border-radius: 20px !important;
        width: 200px !important;
    }
    .ant-popover-inner{
        border-radius: 5px;
    }
    .ant-popover-inner-content{
        width: 250px !important;
        padding: .5em 1em;
    }
