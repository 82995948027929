
.Search-main {
     .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
         border: 1px solid var(--color-borderLine) !important;
         border-radius: 2px 0px 0px 2px !important;
         padding: 4.2px 15px !important;
     }
     .ant-input-group-addon:last-child{

    }
    .ant-input-group-addon{
        .ant-btn-primary{
            border-left: 0px solid var(--color-borderLine);
            border-top: 1px solid var(--color-borderLine);
            border-bottom: 1px solid var(--color-borderLine);
            border-right: 1px solid var(--color-borderLine);
        }
  }
}
