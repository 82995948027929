.inputBoxField {
    border: 1px solid var(--color-borderLine) !important;
    border-radius: 5px !important;
    padding: 6px 15px!important;
:hover,:active,:focus{
    border-color: var(--color-primary);
}
}
.errorInputSearchStyles{
    color: var(--color-red);
    padding-top: 8px;
    margin:2px 0 !important;
}