$tablet: 1024px;
$mobile-max: 480px;

@mixin tablet {
    @media screen  and (max-width: #{$tablet}) { // Tablet 
        @content;
    }
}

@mixin mobile {
    @media screen  and (max-width: #{$mobile-max}) { // Tablet 
        @content;
    }
}

.companiesListStyles{
    .companiesTitleStyles{
        width: 100%;
        background-color: var(--color-primaryBlue);
        color: var(--color-white);
        border-radius: 5px 5px 0 0;
        margin-bottom: 7px;
        p:first-child{
            width: 50%;
        }
        p:nth-child(2), p:nth-child(3){
            width: 25%;
        }
        p{
            padding:1rem 2rem;
             @include tablet{
                padding: 1em;
            }
        }
    }
    .companiesStyles{
        width: 100%;
        p:first-child{
            width: 50%;
        }
        p:nth-child(2), p:nth-child(3){
            width: 25%;
        }
        .companyName{
            padding:0.6rem 0 0.6rem 2rem;
            cursor: pointer;
            @include tablet{
                padding:0.6rem 0 0.6rem 1rem;
            }
        }
        .companyCode{
            cursor: pointer;
        }
        .switchBtn{
            padding:0.6rem 0;
        }
    }
}