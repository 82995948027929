.radioBtnTabsStyles .ant-radio-button-wrapper{
    width: 160px;
    text-align: center;
    border:1px solid var(--color-primary);
    color: var(--color-primary);
}
.radioBtnTabsStyles .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    color: var(--color-white);
    background: var(--color-primary);
    border-color: var(--color-primary) !important;
}
.radioBtnTabsStyles .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
    box-shadow: 0 0 0 0 var(--color-primary);
}