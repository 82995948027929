.selectStyles {
    .ant-select-selector{
    border: 1px solidvar(--color-borderLine) !important;
    border-radius: 5px !important;
    padding:2px 15px !important;
    height: 36px !important;
    }
    .ant-select-arrow{
        padding-right: 5px;
    }
 :hover,:active,.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-color:var(--color-primary);
}
}
.selectStyles.borderNone{
     .ant-select-selector,.ant-select-selector:focus-visible{
        border: 0px;
    }
}
.errorDropdownStyles{
    color: var(--color-red);
    padding-top: 8px;
    margin: 0px !important;
}
.navDropdownSelect{
    .ant-select-selector{
        // border: 1px solidvar(--color-primary) !important;
        border-radius: 5px !important;
        padding:2px 15px !important;
        text-align: center;
        height: 36px !important;
        background-color: var(--color-primary) !important;
        color: var(--color-white) !important;
        }
        .ant-select-arrow{
            padding-right: 15px;
        }
}

.selectStyles.width200{
    width: 150px !important;
}